<template>
  <Layout class="bg-gray-50 ">
    <PageHeader />

    <div class="row my-4">
      <div class="col-md-12 flex justify-center">
        <div>
          <div id="profile-container">
            <img id="profileImage" :src="url" />
          </div>

          <label class="btn float-right btn-primary btn-sm">
            <i class="fa fa-plus"></i
            ><input
              id="imageUpload"
              @change="handleImage"
              type="file"
              accept=" image/jpeg, image/jpg, image/png"
              style="display: none;"
              name="image"
            />
          </label>
        </div>
      </div>
    </div>

    <div class="row bg-gray-50 rounded-2xl py-3">
      <div class="col-md-6 rounded-3xl border-2 p-4  ">
        <div class="form-group">
          <label for="fullName">Businness Name</label>
          <input
            type="text"
            name="name"
            class="form-control"
            placeholder="Business Name"
            :value="$store.state.pharmacy.name || profile.businessName"
            @change="updateBusName"
            autocomplete="off"
          />
        </div>
        <div class="form-group">
          <label for="email"> Perm Tag / Store Tag</label>
          <input
            style="background-color: #d3d3d3;"
            disabled
            type="text"
            class="form-control"
            placeholder="Business Perm Tag"
            v-model="profile.permTag"
            autocomplete="off"
          />
        </div>
        <div class="form-group">
          <label for="businessType">Businness Type</label>
          <select
            style="background-color: #d3d3d3;"
            disabled
            class="form-control"
          >
            <option selected value="profile.businessTypeId">{{
              profile.businessTypeId == 1 ? "Hospital" : "Pharmacy"
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="email"> Business Email</label>
          <input
            type="email"
            class="form-control"
            placeholder="Enter your email"
            :value="$store.state.pharmacy.bus_email || profile.email"
            @change="updateBusEmail"
            autocomplete="off"
          />
        </div>

        <div class="form-group">
          <label for="email"> Business Phone Number</label>
          <input
            style="background-color: #d3d3d3;"
            type="button"
            class="form-control disabled text-left"
            placeholder="Business Phone"
            :value="phonenumberupdate || profile.phoneNumber"
          />
        </div>

        <div class="form-group">
          <label for="businessAddress_1">Businness Address </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter Businness  Address"
            :value="$store.state.pharmacy.addr || profile.businessAddress"
            @change="updateBusAddress"
            autocomplete="off"
          />
        </div>

        <!-- <div class="form-group">
          <label for="city">Business Location</label>
          <input
            type="text"
            class="form-control"
            placeholder="getState"
            :value="$store.state.pharmacy.loc || profile.businessLocation"
            @change="updateBusLocation"
            autocomplete="off"
          />
        </div> -->

        <div class="form-group">
          <label for="countries">Select your country</label>
          <select
            class="form-control"
            @change="updateCountry"
            v-model="countryObject"
            name="countries"
          >
            <option v-for="cty in all_countries" :key="cty.id" :value="cty">{{
              cty.fullName
            }}</option>
          </select>
        </div>

        <!-- Updates -->
        <b-form-group id="search_address_group" :label-for="id">
          <label for="countries">Search for your business Location</label>
          <b-form-input
            size="lg"
            ref="autocomplete"
            type="text"
            :id="id"
            v-model="autocompleteText"
          >
          </b-form-input>
        </b-form-group>

        <div class="form-group">
          <label for="search text-">City</label>
          <input
            type="text"
            placeholder="Cape Coast"
            v-model="addressData.city"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="search text-">Street Address</label>
          <input
            type="text"
            placeholder="chaple street, abura"
            class="form-control"
            v-model="address"
          />
        </div>
      </div>
      <div class="col-md-6  mobile">
        <form class="rounded-3xl border-2 p-4">
          <div class="form-row">
            <div v-if="profile.employeeAccount" class="col-md-6 mb-3">
              <label for="BusinessContact"> First Name</label>
              <input
                type="text"
                class="form-control"
                :value="
                  $store.state.pharmacy.fname ||
                    profile.employeeAccount.firstName
                "
                placeholder="first Name"
                @change="updateEmpFName"
              />
            </div>
            <div v-if="profile.employeeAccount" class="col-md-6 mb-3">
              <label class="opacity-0" for="LastName">Last Name</label>
              <input
                type="text"
                class="form-control"
                :value="
                  $store.state.pharmacy.lname ||
                    profile.employeeAccount.lastName
                "
                placeholder="Last Name"
                @change="updateEmpLName"
              />
            </div>
          </div>
          <div v-if="profile.employeeAccount" class="form-group">
            <label for="Contact Person">Admin Email</label>
            <input
              type="email"
              class="form-control"
              name="email"
              :value="
                $store.state.pharmacy.emp_email || profile.employeeAccount.email
              "
              placeholder="Email"
              @change="updateEmpEmail"
            />
          </div>
          <div v-if="profile.employeeAccount" class="form-group">
            <label for="OfficePhone"> Admin Phone</label>
            <input
              style="background-color: #d3d3d3;"
              type="button"
              class="form-control disabled text-left"
              :value="main_admin_phone || profile.employeeAccount.phoneNumber"
            />
          </div>

          <div class="form-group">
            <label for="companyWebsite"> Business Website</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your Business Website url"
              :value="$store.state.pharmacy.web || profile.businessWebsite"
              @change="updateBusWebsite"
              autocomplete="off"
            />
          </div>

          <div class="form-group">
            <label for="businessPhone">Businness Registration Number</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Businness Reg Number"
              :value="$store.state.pharmacy.reg || profile.registrationNumber"
              @change="updateBusReg"
              autocomplete="off"
            />
          </div>

          <div class="form-group">
            <label for="numberOfEmployees"> Number Of Employees</label>
            <input
              type="text"
              :value="
                $store.state.pharmacy.bus_num ||
                  profile.businessNumberOfEmployees
              "
              placeholder="Enter no of employees"
              class="form-control"
              @change="updateNumEmp"
              autocomplete="off"
            />
          </div>

          <div class="form-group">
            <label for="email"> Country</label>
            <input
              style="background-color: #d3d3d3;"
              disabled
              type="text"
              class="form-control"
              placeholder="Country"
              :value="profile.countryId == 1 ? 'Ghana' : ''"
              autocomplete="off"
            />
          </div>
        </form>

        <div class="my-5 py-5 bg-gray-50 rounded-3xl border-2 p-4">
          <p class="text-gray-500">Upload Corporate Affairs Document</p>
          <div class="custom-file mb-5">
            <input
              type="file"
              @change="uploadBusDocs"
              class="custom-file-input"
              accept=".doc,.pdf,.txt,.ppt"
              multiple
              ref="files"
            />
            <label class="custom-file-label" for="validatedCustomFile">{{
              "Choose file..."
            }}</label>
          </div>

          <div
            v-for="(file, key) in Documents"
            :key="file.index"
            class="result bg-oonpayprimary text-white px-4  rounded-2xl py-1 mt-3"
          >
            <div class="d-flex justify-content-between">
              <span> {{ file.name }}</span>
              <span @click="deleteUpdatedDoc(key)" class="text-oonpaysecondary"
                ><i class="fa fa-trash" aria-hidden="true"></i
              ></span>
            </div>
          </div>
          <div
            v-for="(file, key) in profile.documents"
            :key="key"
            class="result bg-oonpayprimary text-white px-4 rounded-2xl py-1 mt-3"
          >
            <div class="d-flex justify-content-between">
              <span> {{ file.fullName }}</span>
              <span @click="deleteDoc(key)" class="text-oonpaysecondary"
                ><i class="fa fa-trash" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>

        <button
          @click.prevent="updateProfile"
          class="btn btn-warning btn-block"
        >
          <b-spinner v-if="loading" type="grow"></b-spinner>
          <span v-else>Update</span>
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import $ from "jquery";
import { mapGetters } from "vuex";
import axios from "axios";
import {  ImagebaseUrl } from '../../../config';


/* eslint-disable */

const ADDRESS_COMPONENTS = {
  subpremise: "short_name",
  street_number: "short_name",
  route: "long_name",
  sublocality_level_1: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "long_name",
  country: "long_name",
  postal_code: "short_name"
};

export default {
  page: {
    title: "Manage Users",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      uniqueParam: Date.now(),
      loading: false,
      otp: null,
      MerchantProfileBaseUrl:ImagebaseUrl,
      confirm_otp: null,
      countries: [],
      phonenumber: null,
      selected_code: null,
      adminphonenumber: null,
      main_admin_phone: null,
      phonenumberupdate: null,
      show_confirm: 1,
      profile: {},
      Documents: [],
      logo: null,
      logoUpload: null,
      url: "",
      businessLogo: null,
      logoTitle: null,

      // updates
      address: "",
      autocomplete: null,
      autocompleteText: "",
      geolocation: {
        geocoder: null,
        loc: null,
        position: null
      },
      country: "",
      countryObject: {},
      id: "this-component",
      types: "address",
      addressData: {
        streetNumber: 0,
        street: "",
        suburb: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        latitude: "",
        longitude: ""
      }
    };
  },

  watch: {
    autocompleteText: function(newVal, oldVal) {
      this.$emit("inputChange", { newVal, oldVal }, this.id);
    },
    country: function(newVal, oldVal) {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country
      });
    }
  },

  computed: {
    ...mapGetters("dashboard", ["all_countries"])
  },

  methods: {
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();

      if (!place.geometry) {
        this.$emit("no-results-found", place, this.id);
        return;
      }
      if (place.address_components !== undefined) {
        this.processFormattedResults(this.formatResult(place));
      }
    },
    formatResult(place) {
      let returnData = {};

      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (ADDRESS_COMPONENTS[addressType]) {
          let val =
            place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }

      returnData["latitude"] = place.geometry.location.lat();
      returnData["longitude"] = place.geometry.location.lng();

      this.$store.dispatch(
        "healthhub/Setlat",
        (returnData["latitude"] = place.geometry.location.lat())
      );
      this.$store.dispatch(
        "healthhub/Setlong",
        (returnData["longitude"] = place.geometry.location.lng())
      );

      return returnData;
    },
    processFormattedResults(formattedRes) {
      console.log(formattedRes);
      this.addressData.streetNumber = formattedRes.street_number;
      this.address = formattedRes.address;
      this.addressData.latitude = formattedRes.latitude;
      this.addressData.longitude = formattedRes.longitude;
      this.addressData.street = formattedRes.route;
      this.addressData.suburb = formattedRes.sublocality_level_1;
      this.addressData.city = formattedRes.locality;
      this.addressData.province = formattedRes.administrative_area_level_1;
      this.addressData.country = formattedRes.country;
      this.addressData.postalCode = formattedRes.postal_code;

      this.$store.dispatch("healthhub/setCity", this.addressData.city);

      this.getAddressFrom(formattedRes.latitude, formattedRes.longitude);
    },

    getAddressFrom(lat, long) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=`
        )
        .then((response) => {
          if (response.data.error_message) {
            console.log(response.data.error_message);
          } else {
            console.log(response.data.results[8].formatted_address);
            this.address = response.data.results[0].formatted_address;
            this.addressData.city = response.data.results[8].formatted_address;
            this.$store.dispatch("healthhub/StreetAddress", this.address);
            this.$store.dispatch("healthhub/setCity", this.addressData.city);
          }
        });
    },

    updateCountry() {
      this.country = this.countryObject.isO2Code.toLowerCase().toLowerCase();
      this.$store.dispatch("healthhub/CountryId", this.countryObject.id);
    },

    updateBusName(e) {
      this.$store.dispatch("pharmacy/UpdateBusName", e.target.value);
    },
    updateBusEmail(e) {
      this.$store.dispatch("pharmacy/UpdateBusEmail", e.target.value);
    },
    updateBusAddress(e) {
      this.$store.dispatch("pharmacy/UpdateBusAddress", e.target.value);
    },
    updateBusLocation(e) {
      this.$store.dispatch("pharmacy/UpdateBusLocation", e.target.value);
    },
    updateBusWebsite(e) {
      this.$store.dispatch("pharmacy/UpdateBusWeb", e.target.value);
    },
    updateBusReg(e) {
      this.$store.dispatch("pharmacy/UpdateBusReg", e.target.value);
    },
    updateNumEmp(e) {
      this.$store.dispatch("pharmacy/UpdateNumEmp", e.target.value);
    },
    updateEmpFName(e) {
      this.$store.dispatch("pharmacy/UpdateEmpFName", e.target.value);
    },
    updateEmpLName(e) {
      this.$store.dispatch("pharmacy/UpdateEmpLName", e.target.value);
    },
    updateEmpEmail(e) {
      this.$store.dispatch("pharmacy/UpdateEmpEmail", e.target.value);
    },
    updateAdminPhone(e) {
      this.$store.dispatch("pharmacy/UpdateAdminPhone", e.target.value);
    },

    UpdateNumber() {
      let newPhone = this.phonenumber;
      if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
        var phone = newPhone.substring(1);
      }
      let data = `${this.selected_code}${phone}`;
      return (this.phonenumberupdate = data);
    },

    getProfile() {
      this.$store.dispatch("invoice/getProfile").then((response) => {
        this.profile = response.data;
        this.addressData.city = this.profile.location.city;
        this.address = this.profile.location.streetAddress;
        this.getLogo();
      });
    },

    uploadBusDocs(e) {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.Documents.push(this.$refs.files.files[i]);
      }
    },

    deleteDoc(key) {
      this.profile.documents.splice(key, 1);
    },

    deleteUpdatedDoc(key) {
      this.Documents.splice(key, 1);
    },

    handleImage(e) {
      this.businessLogo = e.target.files[0];
      this.logoTitle = this.businessLogo.name;
      this.url = URL.createObjectURL(this.businessLogo);
      this.$store.dispatch("pharmacy/UploadBusLogo", this.businessLogo);
    },

    getLogo() {
      this.url = `${ImagebaseUrl}/oonpaymerchants/${encodeURIComponent(
        JSON.parse(localStorage.getItem('userinfo')).data.imageUrl
      )}?cache=${this.uniqueParam}`;
      return this.url;
    },

    getCountry() {
      this.$store.dispatch("dashboard/GetAllCountries");
    },

    updateProfile() {
      const formData = new FormData();

      formData.append(
        "businessname",
        this.$store.state.pharmacy.name || this.profile.businessName
      );
      formData.append("businesstypeid", this.profile.businessTypeId);
      formData.append(
        "businessaddress",
        this.$store.state.pharmacy.addr || this.profile.businessAddress
      );
      formData.append(
        "businesslocation",
        this.$store.state.pharmacy.loc || this.profile.businessLocation
      );
      formData.append(
        "businessnumberOfemployees",
        this.$store.state.pharmacy.bus_num ||
          this.profile.businessNumberOfEmployees
      );
      formData.append(
        "businessWebsite",
        this.$store.state.pharmacy.web || this.profile.businessWebsite
      );
      formData.append("businesspermtag", this.profile.permTag);
      formData.append(
        "businessphonenumber",
        this.phonenumberupdate || this.profile.phoneNumber
      );
      formData.append(
        "businessregistrationnumber",
        this.$store.state.pharmacy.reg || this.profile.registrationNumber
      );
      formData.append(
        "businessemail",
        this.$store.state.pharmacy.bus_email || this.profile.email
      );
      formData.append("businesscountryid", this.profile.countryId);
      formData.append(
        "adminfirstname",
        this.$store.state.pharmacy.fname ||
          this.profile.employeeAccount.firstName
      );
      formData.append(
        "adminlastname",
        this.$store.state.pharmacy.lname ||
          this.profile.employeeAccount.lastName
      );
      formData.append(
        "adminphonenumber",
        this.$store.state.pharmacy.admin_phone_update ||
          this.profile.employeeAccount.phoneNumber
      );
      formData.append(
        "adminemail",
        this.$store.state.pharmacy.emp_email ||
          this.profile.employeeAccount.email
      );

      formData.append(
        "businesstimeStamp",
        JSON.stringify(this.profile.timeStamp)
      );

      formData.append(
        "employeetimeStamp",
        JSON.stringify(this.profile.employeeAccount.timeStamp)
      );

      formData.append("employeeGuid", this.profile.employeeAccount.guid);

      formData.append("merchantGuid", this.profile.guid);

      formData.append("businessLogo", this.$store.state.pharmacy.businessLogo);

      formData.append("merchantImageUrl", this.profile.imageUrl);

      formData.append("businesscity", this.addressData.city);
      formData.append("businesslatitude", this.profile.location.latitude);
      formData.append("businesslongitude", this.profile.location.longitude);
      formData.append("businessstreetAddress", this.address);
      formData.append("documents", JSON.stringify(this.profile.documents));
      var self = this;
      for (let i = 0; i < this.Documents.length; i++) {
        let file = self.Documents[i];
        formData.append("New File", file);
      }

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      this.loading = true;
      this.$store
        .dispatch("invoice/updateProfile", formData)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
            window.localStorage.setItem(
              "merchantGUID",
              response.data.item1.guid
            );

            window.localStorage.setItem(
              "employeeGUID",
              response.data.item1.employeeAccount.guid
            );
            this.$swal({
              icon: "success",
              title: "succes",
              text: "Account updated successfully"
            });

            window.location.reload();
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    }
  },

  mounted() {
    const options = {};

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.id),
      options
    );

    this.autocomplete.addListener("place_changed", this.onPlaceChanged);

    this.getProfile();
    this.getCountry();
  }
};
</script>

<style scoped>
.btn-warning {
  background: #0c4369 !important;
  border: none;
}

.btn-primary {
  background: #f15825;
  border: none;
}

/* #imageUpload
{
    display: none;
} */
input[type="file"] {
  color: transparent;
}
#profileImage {
  cursor: pointer;
}

#profile-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

#profile-container img {
  width: 150px;
  height: 150px;
}
</style>
